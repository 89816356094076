<template>
  <TitleBar>Change Password</TitleBar>
  <div
    class="px-4 md:px-6 lg:px-8 flex align-items-center justify-content-center"
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div>
        <div class="p-field mb-4">
          <label for="emailInput" class="block text-900 font-medium mb-2"
            >Current Password</label
          >
          <InputText
            type="password"
            @keyup="clearValidationErrors"
            id="currenPasswordInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.current_password
                ? 'p-invalid'
                : ''
            "
            v-model="form.current_password"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.current_password"
          >
            {{ validationErrors.current_password[0] }}
          </small>
        </div>

        <div class="p-field mb-3">
          <label for="passwordInput" class="block text-900 font-medium mb-2"
            >New Password</label
          >
          <InputText
            type="password"
            @keyup="clearValidationErrors"
            id="passwordInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.password ? 'p-invalid' : ''
            "
            v-model="form.password"
          />
        </div>
        <div class="p-field mb-3">
          <label for="passwordInput" class="block text-900 font-medium mb-2"
            >New Password Confirmation</label
          >
          <InputText
            type="password"
            @keyup="clearValidationErrors"
            id="passwordInput"
            class="p-inputtext p-component w-full p-3"
            :class="
              validationErrors && validationErrors.password ? 'p-invalid' : ''
            "
            v-model="form.password_confirmation"
          />
          <small
            class="p-error"
            v-if="validationErrors && validationErrors.password"
          >
            {{ validationErrors.password[0] }}
          </small>
        </div>

        <Button
          label="Change Password"
          class="p-button p-component w-full p-3"
          @click.prevent="changePassword"
          :loading="loading"
        ></Button>
      </div>
    </div>
  </div>
</template>
<script>
import TitleBar from "@/components/TitleBar.vue";
import axios from "axios";
export default {
  data() {
    return {
      form: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      validationErrors: null,
      loading: false,
    };
  },
  methods: {
    clearValidationErrors() {
      this.validationErrors = null;
    },
    async changePassword() {
      // Set loading state
      this.loading = true;
      // Clear previous validation errors
      this.validationErrors = null;
      await axios
        .post("/auth/password/authenticated-reset", this.form)
        .then(async (res) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: res.data.message,
            life: 1500,
          });
          this.loading = false;
          this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          this.validationErrors = err.response.data.errors;
          console.log(this.validationErrors);
          this.loading = false;
        });
    },
  },
  components: { TitleBar },
};
</script>
